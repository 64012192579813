import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {
  getProjectAttributions,
  updateProjectAttributions
} from "../../../../../actions/project";
import {useEffect, useState} from "react";
import cls from './Attributions.module.scss';
import {projectAttributions, projectGeneral} from "../../../../../redux/slices/project";
import {clearUnSaveData, setUnSaveData} from "../../../../../redux/slices/warnings";
import {useCallbackPrompt} from "../../../../../hooks/useCallbackPrompt";
import {ModalName, openModal} from "../../../../../redux/slices/modals";
import Select from "../../../../../components/ui/Select";

import './style.scss';
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {trans} from "../../../../../_locales";
import {Models, Windows} from "../../../../../data";
import {getScreenResolution, sendSdk} from "../../../../../utils";

const Attributions = () => {

  const dispatch = useAppDispatch();
  const {projectId} = useParams();
  const reduxUser = useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const currentProject = useAppSelector(projectGeneral);
  const currentAttr = useAppSelector(projectAttributions);
  const language = useAppSelector(currentUserLang);
  const [dataProject, setDataProject] = useState(currentAttr);
  const [exitCounter, setExitCounter] = useState<boolean>(false);
  const navigate = useNavigate();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('settings_attributions') || currentProject && currentProject.owner_id === reduxUserId;
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('settings_attributions') || currentProject && currentProject.owner_id === reduxUserId;
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('settings_attributions') || currentProject && currentProject.owner_id === reduxUserId;
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('settings_attributions') || currentProject && currentProject.owner_id === reduxUserId;

  useEffect(()=> {
    if (projectId && reduxUserId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
          'page': 'settings_attributions',
          'resolution': getScreenResolution()
        }
      })
    }
    if(permissions && !isView) {
      navigate(`/projects/${projectId}/settings/`)
    }
  }, [projectId, reduxUserId])

  useEffect(() => {
    dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then(()=> {
      setDataProject(currentAttr);
    })
  }, [projectId, reduxUser.id])

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(exitCounter)
  if(showPrompt) {
    dispatch(openModal({
      name:ModalName.CONFIRM_EXIT,
      data: {
        confirm: confirmNavigation,
        cancel: cancelNavigation,
      }
    }))
  }


  const windows:any = Windows(language);
  const onChangeWindowsValue = (response:object) => {
    for(const key in response) {
      changeWindow(key)
    }
  }
  function changeWindow(model: string): void  {
    if(!exitCounter && dataProject === null) {
      setDataProject(currentAttr);
    }
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null) {
      setDataProject({...dataProject, attribution_window: Number(model)});
    }
  }

  const models:any = Models(language);
  const onChangeModelsValue = (response:object) => {
    for(const key in response) {
      changeModel(key)
    }
  }
  function changeModel(model: string): void  {
    if(!exitCounter && dataProject === null) {
      setDataProject(currentAttr);
    }
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null) {
      setDataProject({...dataProject, attribution_model: model});
    }
  }


  const reportPeriodList:any = {
    'month': trans('This month', language),
    'previous_month': trans('Previous month', language),
    'week': trans('This week', language),
    'previous_week': trans('Previous week', language),
  }
  const onChangeReportsValue = (response:object) => {
    for(const key in response) {
      changeReportPeriod(key)
    }
  }
  function changeReportPeriod(model: string): void  {
    if(!exitCounter && dataProject === null) {
      setDataProject(currentAttr);
    }
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null) {
      setDataProject({...dataProject, attribution_period: model});
    }
  }

  const onSubmit = () => {
    setExitCounter(false)
    dispatch(clearUnSaveData());
    dispatch(updateProjectAttributions({data: dataProject, projectId: Number(projectId)}));
    sendSdk('event', {
        'event_type': 'edit',
        'event_category': 'settings_attributions',
        'event_name': 'active',
        'event_value': '10',
        'event_params': {
            'project_id': projectId,
            'user_id': reduxUserId
        }
    });
  }

  if(isEdit || isDelete) {
    return (
      <>
      <div className={cls.content}>
        <div className={cls.form}>
          <div className={cls.block}>
            <div className={cls.title}>{trans('Attribution', language)}</div>
            <div className={cls.input_entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Default attribution window', language)}</label>
                <span className={cls.data}>
                  {dataProject ?
                    (windows as any)[`${dataProject.attribution_window}`] :
                    (windows as any)[`${currentAttr?.attribution_window}`]}
                </span>
              </div>
              <div className={`${cls.select_wrapper} attributions__select-wrapper`}>
                <Select selectActive={ dataProject && dataProject.attribution_window ? (windows as any)[`${dataProject.attribution_window}`] : currentAttr && currentAttr.attribution_window ?
                    (windows as any)[`${currentAttr?.attribution_window}`] : trans('Select the attribution window', language)} dataset={windows} onChangeActive={onChangeWindowsValue} />
              </div>
            </div>
            <div className={cls.input_entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Default attribution model', language)}</label>
                <span className={cls.data}>
                  {dataProject ?
                    (models as any)[`${dataProject.attribution_model}`] :
                    (models as any)[`${currentAttr?.attribution_model}`]}
                </span>
              </div>
              <div className={`${cls.select_wrapper} attributions__select-wrapper`}>
                <Select selectActive={ dataProject && dataProject.attribution_model ?
                    (models as any)[`${dataProject.attribution_model}`] : currentAttr && currentAttr.attribution_model ?
                    (models as any)[`${currentAttr?.attribution_model}`] : trans('Select the attribution model', language)} dataset={models} onChangeActive={onChangeModelsValue} />
              </div>
            </div>
          </div>
          <div className={cls.block}>
            <div className={cls.title}>{trans('Reports', language)}</div>
            <div className={cls.input_entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Default relative time interval', language)}</label>
                <span className={cls.data}>
                  {dataProject ?
                    (reportPeriodList as any)[`${dataProject.attribution_period}`] :
                    (reportPeriodList as any)[`${currentAttr?.attribution_period}`]}
                </span>
              </div>
              <div className={`${cls.select_wrapper} attributions__select-wrapper`}>
                <Select selectActive={dataProject ?
                    (reportPeriodList as any)[`${dataProject.attribution_period}`] : currentAttr && currentAttr.attribution_period ?
                    (reportPeriodList as any)[`${currentAttr?.attribution_period}`] : trans('Select the attribution period', language)} dataset={reportPeriodList} onChangeActive={onChangeReportsValue} />
              </div>
            </div>
          </div>
        </div>
        <button className={exitCounter ? cls.submit_btn : cls.submit_btn_disactive } onClick={onSubmit}>
          {trans('Save changes', language)}
        </button>

      </div>
      </>
    )} else {
      return (
        <div className={cls.content}>
          <div className={cls.form}>
            <div className={cls.block}>
              <div className={cls.title}>{trans('Attribution', language)}</div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='project_window'>{trans('Default attribution window', language)}</label>
                  <span className={cls.data}>{dataProject ? dataProject.attribution_window : currentAttr?.attribution_window} {trans('days', language)}</span>
                </div>
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='project_name'>{trans('Default attribution model', language)}</label>
                  <span className={cls.data}>{ dataProject && dataProject.attribution_model ?
                    (models as any)[`${dataProject.attribution_model}`] : currentAttr && currentAttr.attribution_model &&
                    (models as any)[`${currentAttr?.attribution_model}`]}</span>
                </div>
              </div>
            </div>
            <div className={cls.block}>
              <div className={cls.title}>{trans('Reports', language)}</div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='project_name'>{trans('Default relative time interval', language)}</label>
                  <span className={cls.data}>{dataProject ?
                    (reportPeriodList as any)[`${dataProject.attribution_period}`] : currentAttr && currentAttr.attribution_period &&
                    (reportPeriodList as any)[`${currentAttr?.attribution_period}`]}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  // }

};

export default Attributions;