import './style.scss';
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {projectGeneral} from "../../../../../redux/slices/project";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getScreenResolution, sendSdk} from "../../../../../utils";
import {trans} from "../../../../../_locales";
import Btn from "../../../../../ui/Btn";
import {closeModal, ModalName, openModal} from "../../../../../redux/slices/modals";
import iconEdit from "../../../../../assets/icons/edit.svg";
import iconRemove from "../../../../../assets/icons/trash.svg";
import iconEmpty from "../../../../../assets/icons/empty.svg";
import {createProjectRole, deleteProjectRole, getRoles, updateProjectRole} from "../../../../../actions/projects";
import {reduxRoles} from "../../../../../redux/slices/roles";

const SettingsRoles = () => {
    const language = useAppSelector(currentUserLang);
    const reduxUserId = useAppSelector(userId);
    const reduxData = useAppSelector(reduxRoles);
    const reduxUser = useAppSelector(currentUser);
    const currentProject = useAppSelector(projectGeneral);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {projectId} = useParams();
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('settings_roles') || currentProject && currentProject.owner_id === reduxUserId;
    const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('settings_roles') || currentProject && currentProject.owner_id === reduxUserId;
    const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('settings_roles') || currentProject && currentProject.owner_id === reduxUserId;
    const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('settings_roles') || currentProject && currentProject.owner_id === reduxUserId;

    const [wrapper, setWrapper] = useState<Array<any>>([])

    useEffect(()=> {
        if (projectId && reduxUserId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'settings_roles',
                    'resolution': getScreenResolution()
                }
            })
            if(permissions && !isView) {
                navigate(`/projects/${projectId}/settings/`);
            }
        }
    }, [projectId, reduxUserId, permissions])

    useEffect(()=> {
        dispatch(getRoles({projectId: Number(projectId), permissions: true}));
    }, [projectId])
    useEffect(()=> {
        if(reduxData && Array.isArray(reduxData)) {
            setWrapper(reduxData)
        }
    }, [reduxData])

    const createRole = (response:any) => {
        if(response) {
            dispatch(createProjectRole({projectId:Number(projectId), userId: reduxUserId, data: response}));
            sendSdk('event', {
                'event_type': 'create',
                'event_category': 'settings_api',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId
                }
            });
            dispatch(closeModal(ModalName.SETTINGS_ROLE_CREATE));
        }
    }
    const editRole = (id:number, response:any) => {
        if(response) {
            dispatch(updateProjectRole({projectId: Number(projectId), userId: reduxUserId, data: response, roleId: id}));
            sendSdk('event', {
                'event_type': 'edit',
                'event_category': 'settings_api',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'role_id': id
                }
            });
        }
    }
    const removeRole = (id:number) => {
        dispatch(deleteProjectRole({projectId:Number(projectId), roleId: id}));
        sendSdk('event', {
            'event_type': 'delete',
            'event_category': 'settings_api',
            'event_name': 'active',
            'event_value': '10',
            'event_params': {
                'project_id': projectId,
                'user_id': reduxUserId,
                'token_id': id
            }
        });
    }

    return(
        <div className='settings-roles'>
            <div className="settings-roles__head">
                <div className="settings-roles__wrapper">
                    <h3 className='settings-roles__title'>{trans('Roles', language)}</h3>
                    <p className='settings-roles__description'>{trans('In this section, you can create a custom role, restricting access only to the necessary data and functions of the application.', language)}</p>
                </div>
                {isCreate && <Btn
                    className={'settings-roles__add'}
                    text={trans('Create new role', language)}
                    color={'blue'}
                    onClick={() => {
                        dispatch(openModal({
                            name: ModalName.SETTINGS_ROLE_CREATE,
                            data: {
                                projectId: projectId,
                                name: '',
                                onSubmit: createRole
                            }
                        }));
                        sendSdk('event', {
                            'event_type': 'open',
                            'event_category': 'settings_roles',
                            'event_name': 'create',
                            'event_value': '10',
                            'event_params': {
                                'project_id': projectId,
                                'user_id': reduxUserId
                            }
                        });
                    }}
                />}
            </div>
            <div className="settings-roles__table-head">
                <table className='settings-roles__table'>
                    <thead>
                        <tr>
                            <th>{trans('Role', language)}</th>
                            <th>{trans('Author', language)}</th>
                            <th>{trans('Creation date', language)}</th>
                            <th></th>
                        </tr>
                    </thead>
                </table>
            </div>
                { wrapper.length > 0 && <div className='settings-roles__table-scroll'><table className='settings-roles__table'><tbody>
                    {wrapper.map((item:any)=> (
                        <tr key={item.id}>
                            <td>
                                <div className="settings-roles__block">
                                    <div className="settings-roles__wrapper">
                                        <div className="settings-roles__name">{item.title}</div>
                                    </div>
                                </div>
                            </td>
                            <td>{item.author ? item.author : '-'}</td>
                            <td>{item.created_at ? item.created_at : '-'}</td>
                            <td>
                                <div className="settings-roles__btns">
                                    {(isEdit || item.author_id === reduxUserId) && item.permissions && <button className="settings-roles__btn"
                                        onClick={() => {
                                            dispatch(openModal({
                                                name: ModalName.SETTINGS_ROLE_EDIT,
                                                data: {
                                                    projectId: projectId,
                                                    name: item.title,
                                                    onSubmit: (r:any)=> {
                                                        editRole(item.id, r);
                                                        dispatch(closeModal(ModalName.SETTINGS_ROLE_EDIT));
                                                    },
                                                    defaultData: item.permissions
                                                }
                                            }));
                                            sendSdk('event', {
                                                'event_type': 'open',
                                                'event_category': 'settings_api',
                                                'event_name': 'edit',
                                                'event_value': '10',
                                                'event_params': {
                                                    'project_id': projectId,
                                                    'user_id': reduxUserId
                                                }
                                            });
                                        }}
                                    >
                                        <img src={iconEdit} alt=""/>
                                    </button>}
                                    {(isDelete || item.author_id === reduxUserId) && item.permissions && <button className="settings-roles__btn" onClick={() => {
                                        removeRole(item.id)
                                    }}>
                                        <img src={iconRemove} alt=""/>
                                    </button>}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody> </table></div>}
            {wrapper.length === 0 && <div className='settings-roles__empty'>
                <img src={iconEmpty} alt=""/>
                <p>{trans("You haven't created any roles yet", language)}</p>
            </div>}
        </div>
    )
}

export default SettingsRoles;
