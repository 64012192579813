import {useEffect, useState} from "react";
import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import cx from "classnames";

import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";

import TimeFilter from "../../../../../components/ui/TimeFilter";
import Select from "../../../../../components/ui/Select";
import ShortInfo from "../components/ShortInfo";
import DashboardsChart from "../components/Charts";
import {getDateAgo} from "../../../../../components/ui/TimeFilter/functions";
import DashboardsTable from "../components/Table";

import iconModel from "../../../../../assets/icons/model.svg";
import iconWindow from "../../../../../assets/icons/window.svg";
import iconPeriod from "../../../../../assets/icons/period.svg";

import {
    getProjectAttributions,
    getProjectGeneral
} from "../../../../../actions/project";

import {
    formatDate,
    getScreenResolution,
    isDate,
    isNumber,
    parseFilter,
    QueueServer,
    sendSdk
} from "../../../../../utils";

import '../style.scss';
import {trans} from "../../../../../_locales";
import {onChangeFilterQuery, onLoadingSelectDate, validateModel, validateScale, validateWindow} from "../functions";
import {Groups, Models, Windows} from "../../../../../data";
import {onChangeGeneralSelect} from "../../../../../components/ui/Select/functions";
import {
    dashboardsSettings, setFilter as setDispatchFilter,
    setSetting,
    setSettingModel, setSettingScale,
    setSettingWindow
} from "../../../../../redux/slices/dashboards";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {getPages} from "../../../../../components/SideMenu/Role";
import {IFilterMediaArray} from "../../../../../components/Tables/interfaces";

const DashboardUserBehavior = () => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const language = useAppSelector(currentUserLang);
    const settings = useAppSelector(dashboardsSettings);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isViewGeneral = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_general');
    const isViewEcom = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_ecommerce');
    const isViewUserBehavior = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_user_behavior');

    const [firstLoading, setFirstLoading] = useState(true);
    const defaultPeriodStart = searchParams.get("ps") || '';
    const defaultPeriodEnd = searchParams.get("pe") || '';
    const defaultModel = searchParams.get("m") || '';
    const defaultWindow = searchParams.get("w") || '';
    const defaultScale = searchParams.get("s") || '';
    const defaultFilter = searchParams.get("f") || '';

    useEffect(()=> {
        if(projectId && reduxUserId) {
            sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'user_behavior', 'resolution': getScreenResolution()}})
        }
    }, [projectId, reduxUserId])

    useEffect(() => {
        const tempBreadcrumbs:any = [
            {
              href: '/projects/',
              title: 'Проект',
            },
            {
              href: `/projects/${projectId}/reports/`,
              title: trans('Project reporting', language)
            },
            {
              href: `#`,
              title: trans('User behavior analysis', language),
              dataset: {}
            }
        ]
        if(isViewGeneral) {
            tempBreadcrumbs[2].dataset['general'] = {
                title: trans('General indicators', language),
                href: `/projects/${projectId}/reports/general`
            }
        }
        if(isViewEcom) {
            tempBreadcrumbs[2].dataset['ecommerce'] = {
                title: trans('E-Commerce Reports', language),
                href: `/projects/${projectId}/reports/ecommerce`
            }
        }
        if(projectId && (+projectId === 12484 || +projectId===12483)) {
            tempBreadcrumbs[2].dataset['getintent'] = {
                title: 'GetIntent',
                href: `/projects/${projectId}/reports/getintent`
            }
        }
        if(projectId && (+projectId === 12523)) {
            tempBreadcrumbs[2].dataset['postview_sessions'] = {
                title: 'PostView Sessions',
                href: `/projects/${projectId}/reports/postview_sessions`
            }
        }
        dispatch(setBreadcrumbs(tempBreadcrumbs));
        dispatch(clearInstructions());
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'dashboards'
        })))
        dispatch(getProjectGeneral({currentUserId: reduxUser.id,projectId: Number(projectId)}));

        if(validateScale(defaultScale)) {
            setCategorize(defaultScale)
        } else if(settings && settings.categorize) {
            setCategorize(validateScale(settings.categorize, 'day'))
        } else {
            setCategorize('day');
        }
    }, [dispatch, projectId, permissions]);

    useEffect(()=> {
        if(settings && Object.keys(settings).length > 0) {
            if (sessionStorage.getItem("settings")) {
                const sessionSettings = sessionStorage.getItem("settings");
                const parsed = sessionSettings && JSON.parse(sessionSettings);
                const temp = {
                    period: settings.period ? settings.period : parsed.period,
                    model: settings.model ? settings.model : parsed.model,
                    window: settings.window ? settings.window : parsed.window,
                    categorize: settings.categorize ? settings.categorize : parsed.categorize,
                    events: settings.events ? settings.events : parsed.events
                }
                sessionStorage.setItem("settings", JSON.stringify(temp));
            } else sessionStorage.setItem("settings", JSON.stringify(settings));
        } else if(sessionStorage.getItem("settings")) {
            const sessionSettings = sessionStorage.getItem("settings");
            const parsed = sessionSettings && JSON.parse(sessionSettings);
            parsed && dispatch(setSetting(parsed));
        }
    }, [settings])

    const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow');

    //-------Filter--------
    const [periodStart, setPeriodStart] = useState(isDate(defaultPeriodStart) ? defaultPeriodStart : '');
    const [periodEnd, setPeriodEnd] = useState(isDate(defaultPeriodEnd) ? defaultPeriodEnd : '');
    const [model, setModel] = useState(validateModel(defaultModel, 'mtd'));
    const [attribution_window, setWindow] = useState<number>(validateWindow(defaultWindow, 30) || 30);
    const [categorize, setCategorize] = useState(validateScale(defaultScale, 'day'));
    const [selectDate, setSelectDate] = useState<string>(isDate(defaultPeriodStart)&&isDate(defaultPeriodEnd) ? `${defaultPeriodStart} - ${defaultPeriodEnd}` : trans('Select the period', language));
    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
    }
    const datasetModel = Models(language);
    const datasetWindow = Windows(language);
    const datasetCategorize = Groups(language);

    useEffect(() => {
        if(firstLoading) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
                if(r.payload) {
                    const sessionSettings = sessionStorage.getItem("settings");
                    const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                    if(validateModel(defaultModel)) {
                        setModel(defaultModel)
                    } else if(parseSettings && parseSettings.model) {
                        setModel(validateModel(parseSettings.model, 'fl'))
                    } else setModel(validateModel(r.payload.attribution_model, 'fl'));
                    if(defaultWindow && isNumber(defaultWindow) && ['15', '30', '60', '90'].includes(defaultWindow)) {
                        setWindow(+defaultWindow)
                    } else if(parseSettings && parseSettings.window) {
                        setWindow(parseSettings.window)
                    } else setWindow(r.payload.attribution_window);
                    if(validateScale(defaultScale)) {
                        setCategorize(defaultScale)
                    } else if(parseSettings && parseSettings.categorize) {
                        setCategorize(validateScale(parseSettings.categorize, 'day'))
                    } else {
                        setCategorize('day');
                    }
                    if(isDate(defaultPeriodStart) && isDate(defaultPeriodEnd)) {
                        setPeriodStart(defaultPeriodStart);
                        setPeriodEnd(defaultPeriodEnd);
                        setSelectDate(`${defaultPeriodStart} - ${defaultPeriodEnd}`);
                    } else if (parseSettings && parseSettings.period) {
                        setPeriodStart(parseSettings.period.start);
                        setPeriodEnd(parseSettings.period.end);
                        setSelectDate(`${parseSettings.period.start} - ${parseSettings.period.end}`);
                    } else
                        onLoadingSelectDate({period: r.payload.attribution_period, setPeriodStart, setPeriodEnd, setValue: setSelectDate});
                }
            }).catch((e: any)=> {
                const sessionSettings = sessionStorage.getItem("settings");
                    const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                    if(validateModel(defaultModel)) {
                        setModel(defaultModel)
                    } else if(parseSettings && parseSettings.model) {
                        setModel(validateModel(parseSettings.model, 'fl'))
                    }
                    if(defaultWindow && isNumber(defaultWindow) && ['15', '30', '60', '90'].includes(defaultWindow)) {
                        setWindow(+defaultWindow)
                    } else if(parseSettings && parseSettings.window) {
                        setWindow(parseSettings.window)
                    }
                    if(validateScale(defaultScale)) {
                        setCategorize(defaultScale)
                    } else if(parseSettings && parseSettings.categorize) {
                        setCategorize(validateScale(parseSettings.categorize, 'day'))
                    } else {
                        setCategorize('day');
                    }
                    if(isDate(defaultPeriodStart) && isDate(defaultPeriodEnd)) {
                        setPeriodStart(defaultPeriodStart);
                        setPeriodEnd(defaultPeriodEnd);
                        setSelectDate(`${defaultPeriodStart} - ${defaultPeriodEnd}`);
                    } else if (parseSettings && parseSettings.period) {
                        setPeriodStart(parseSettings.period.start);
                        setPeriodEnd(parseSettings.period.end);
                        setSelectDate(`${parseSettings.period.start} - ${parseSettings.period.end}`);
                    } else
                        onLoadingSelectDate({period: 'month', setPeriodStart, setPeriodEnd, setValue: setSelectDate});
            })
        }
    }, [projectId, reduxUser.id])

    const queue = new QueueServer();

    //Hide header when scroll
    const [lastScroll, setLastScroll] = useState(0);
    const defaultOffset = 100;
    const containHide = (elem:HTMLElement) => elem.classList.contains('hide');

    const handleScroll = (event:any) => {
        const { scrollTop, scrollHeight, offsetHeight } = event.target;
        const header = document.querySelector('header');
        const body = document.querySelector('body');
        const content = document.querySelector('.page-content');
        if(body && header && content && scrollTop > lastScroll && !containHide(header) && scrollTop > defaultOffset && (scrollTop < scrollHeight - offsetHeight - 200)) {
            header.classList.add('hide');
            content.classList.add('stretch');
            body.classList.add('no-scroll');
        } else if(body && header && content && scrollTop < lastScroll && containHide(header)) {
            header.classList.remove('hide');
            content.classList.remove('stretch');
            body.classList.remove('no-scroll');
        }
        setLastScroll(scrollTop);
    }

    const onChangeQuery = ({
        periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter
    }:{
        periodStart?: string,
        periodEnd?: string,
        model?: string,
        attr_window?: number,
        scale?: string,
        eventTargetActive?: Array<string>,
        filter?: IFilterMediaArray
    }) => {
        const query = onChangeFilterQuery({periodStart, periodEnd, model, attr_window, scale, eventTargetActive, filter});
        const temp = {
            search: query
        }
        navigate(temp);
    };

    useEffect(()=> {
        if(!firstLoading) {
            onChangeQuery({
                periodStart,
                periodEnd,
                model,
                attr_window: attribution_window,
                scale: categorize
            });
        }
    }, [periodStart, periodEnd, model, attribution_window, categorize])


  return (
    <div className={contentClassName} onScroll={()=>null}>
        <div className="dashboard-page-filter">
            <div className="dashboard-page-filter__date">
              <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
            </div>
            <div className="dashboard-page-filter__select">
              <Select
                    selectActive={(datasetModel as any)[model]}
                    icon={iconModel}
                    dataset={datasetModel}
                    onChangeActive={(res:any) => onChangeGeneralSelect(res, (value:any) => {
                      setModel(value);
                      dispatch(setSettingModel(value))
                    })}
                />
            </div>
            <div className="dashboard-page-filter__select">
              <Select selectActive={`${trans('Attribution window', language)}: ${attribution_window} ${trans('days', language)}`} icon={iconWindow} dataset={datasetWindow} onChangeActive={(res:any)=> {
                  onChangeGeneralSelect(res, (value:any) => {
                      const result = Number(value);
                      if(result === 15 || result === 30 || result === 60 || result === 90) {
                          setWindow(result);
                          dispatch(setSettingWindow(result))
                      }
                  });
              }} />
            </div>
            <div className="dashboard-page-filter__select">
              <Select
                  selectActive={`${trans('Scale', language)}: ${(datasetCategorize as any)[categorize]}`}
                  icon={iconPeriod}
                  dataset={datasetCategorize}
                  onChangeActive={(res:any)=> onChangeGeneralSelect(res, (value:any) => {
                      setCategorize(value);
                      dispatch(setSettingScale(value))
                  })} />
            </div>
        </div>
        <div className="dashboard-page-summary">
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'behavior_index_uniq_user'}
                    titleDefault={trans('behavior_index_uniq_user', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'behavior_index_cr_returning_user'}
                    titleDefault={trans('behavior_index_cr_returning_user', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'behavior_index_returning_user'}
                    titleDefault={trans('behavior_index_returning_user', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'behavior_index_lead'}
                    titleDefault={trans('behavior_index_lead', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'behavior_index_avg_time'}
                    titleDefault={trans('behavior_index_avg_time', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    queue={queue}
                />
            </div>
            <div className="dashboard-page-summary__item">
                <ShortInfo
                    name={'behavior_index_avg_page_amt'}
                    titleDefault={trans('behavior_index_avg_page_amt', language)}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    queue={queue}
                />
            </div>
        </div>

        <div className="dashboard-page-block">
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram">
                    <DashboardsChart
                        name={'behavior_chart_distr_time_between'}
                        titleDefault={trans('behavior_chart_distr_time_between', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'Bar'}
                        queue={queue}
                    />
                </div>
            </div>
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram">
                    <DashboardsChart
                        name={'behavior_chart_distr_chain_number'}
                        titleDefault={trans('behavior_chart_distr_chain_number', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'Bar'}
                        queue={queue}
                    />
                </div>
            </div>
        </div>

        <div className="dashboard-page-table">
            <div className="dashboard-page__table">
                <DashboardsTable
                    name={'behavior_table_chain_popularity'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    categorize={categorize}
                    queue={queue}
                    hideTotal={true}
                />
            </div>
        </div>

        <div className="dashboard-page-block">
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__table">
                    <DashboardsTable
                        name={'behavior_table_page_popular'}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        categorize={categorize}
                        queue={queue}
                        hideTotal={true}
                    />
                </div>
            </div>
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__table">
                    <DashboardsTable
                        name={'behavior_table_ad_source'}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        categorize={categorize}
                        queue={queue}
                        hideTotal={true}
                    />
                </div>
            </div>
        </div>
        <div className="dashboard-page-block size-l">
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram size-xs">
                    <DashboardsChart
                        name={'behavior_chart_distr_device'}
                        titleDefault={'behavior_chart_distr_device'}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'Pie'}
                        positionLabels={'right'}
                        queue={queue}
                    />
                </div>
            </div>
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram size-xs">
                    <DashboardsChart
                        name={'behavior_chart_os_distr'}
                        titleDefault={trans('behavior_chart_os_distr', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'Pie'}
                        positionLabels={'right'}
                        queue={queue}
                    />
                </div>
            </div>
            <div className="dashboard-page-block__item">
                <div className="dashboard-page__diagram size-xs">
                    <DashboardsChart
                        name={'behavior_chart_distr_browser'}
                        titleDefault={trans('behavior_chart_distr_browser', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        type={'Pie'}
                        positionLabels={'right'}
                        queue={queue}
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

export default DashboardUserBehavior;