import { FC, useEffect, useState, useRef } from 'react'
import {useNavigate} from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

import {currentUser, currentUserLang, userId} from '../../redux/slices/user'
import {
  errorValue,
  getIsLoading,
  projectsData,
  projectsLoaded,
  removeError
} from '../../redux/slices/projects'
import {setBreadcrumbs} from "../../redux/slices/breadcrumbs";
import {setSidebar} from "../../redux/slices/sidebar";

import { downloadProjectsData } from '../../actions/projects'

import { SearchForm, Btn } from '../../ui'
import {itemsFilterFunction, useShowError} from '../../helpers'

import {
  Options,
  HeaderTable,
  Table, Loader,
} from '../../components'
import {Projects as sidebarData} from "../../components/SideMenu/Role/general";

import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg'

import { IProjectsPage } from './interfaces.d'
import cx from 'classnames'
import Pagination from "../../components/ui/Pagination";
import {getCountPage} from "../../components/Tables/TablePagination/functions";
import {trans} from "../../_locales";
import {getScreenResolution, getValueByNestedKey, sendSdk} from "../../utils";
import {ModalName, openModal} from "../../redux/slices/modals";
import {setInstructions} from "../../redux/slices/instructions";

const Projects: FC<IProjectsPage> = ({ noProjects }) => {
  const reduxUserId = useAppSelector(userId);
  const reduxCurrentUser = useAppSelector(currentUser);
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const reduxProjectsData = useAppSelector(projectsData);
  const reduxProjectsLoaded =  useAppSelector(projectsLoaded);
  const language = useAppSelector(currentUserLang);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(()=> {
      if (reduxUserId) {
          sendSdk('page_view', {
              'user': {
                  'uid': reduxUserId
              },
              'event_params': {
                  'user_id': reduxUserId,
                  'page': 'projects',
                  'resolution': getScreenResolution()
              }
          })
      }
  }, [reduxUserId])

  const reduxUserIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (reduxUserId && reduxUserId !== reduxUserIdRef.current) {
      reduxUserIdRef.current = reduxUserId;
      dispatch(downloadProjectsData());
    }
  }, [reduxUserId, dispatch]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
        {
          href: '#',
          title: trans('Projects', language),
        },
      ]))
    dispatch(setSidebar(sidebarData()))
    dispatch(setInstructions({
        title: trans('Instruction', language),
        cb: ()=> {
            dispatch(openModal({
                name: ModalName.DOCS_CREATE_PROJECT,
                data: {}
            }))
        }
    }))
    if(sessionStorage.getItem('settings')) sessionStorage.removeItem("settings");
  }, [dispatch]);

  useShowError(errorMessage as string, removeError);

  const tableData = reduxProjectsData;

  const [searchValue, setSearchValue] = useState<string>('');
  const [filterFormData, setFilterFormData] = useState<FormData>(new FormData);
  const [dataWrapper, setDataWrapper] = useState<Array<any>>([...tableData]);

  useEffect(()=> {
    setDataWrapper([...tableData])
  },[reduxProjectsData])

  const tableDataFiltered = dataWrapper.filter((item) => {
    return itemsFilterFunction({
      formData: filterFormData,
      statusField: item.status,
      dateField: item.created_at,
      nameField: item.name,
      searchValue: searchValue
    });
  });

  const contentClassName = cx('page-content__content', {'empty': noProjects || tableData.length === 0});

  // const titleName = ['','name',`stats["impression"]`,'stats["clicks"]','stats["spent"]','stats["leads"]','stats["postview_cpa"]','created_at']
  const titleName = ['name',`status`,'owner','created_at']
  const onChangeSortTableCustom = (event:any, index:number) => {
        const element = event.target.closest('th');
        const tempData = [...dataWrapper];

        setDataWrapper(tempData.sort(function (a, b) {
            const first = getValueByNestedKey(a, titleName[index]),
                second = getValueByNestedKey(b, titleName[index]);
            if (first < second) {
                return 1;
            }
            if (first > second) {
                return -1;
            }
            return 0;
        }));
        if(element.classList.contains('ascending')) {
          const tempData = [...dataWrapper];
          setDataWrapper(tempData.sort(function (a, b) {
              if (a[titleName[index]] < b[titleName[index]]) {
                return 1;
              }
              if (a[titleName[index]] > b[titleName[index]]) {
                return -1;
              }
              return 0;
          }));
          element.className = 'descending';
          return;
        }
        if(element.classList.contains('descending')) {
          element.className = '';
          setDataWrapper([...tableData]);
          return;
        }
        setDataWrapper(tempData.sort(function (a, b) {
          const first = getValueByNestedKey(a, titleName[index]),
                second = getValueByNestedKey(b, titleName[index]);
          if (first > second) {
            return 1;
          }
          if (first < second) {
            return -1;
          }
          return 0;
        }));
        Array.from(element.parentNode.childNodes).map((item:any) => (
            item.className = ''
        ))
        element.className = 'ascending';
  }

  const [limit, setLimit] = useState(30);
  const per_page_max = getCountPage(tableDataFiltered.length, limit);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <div className={contentClassName}>
        <div className="page-content__header">
              <SearchForm
                value={searchValue}
                onChangeCb={(value) => {
                  setSearchValue(value);
                }}
              />
              <Options
                items={
                  reduxCurrentUser.can_create_project ? [
                  {
                    type: 'filter',
                    tooltip: trans('Filter', language),
                    data: {
                      applyCallback: (data:FormData) => {
                          setFilterFormData(data);
                          // _targetadsTag('event', {
                          //     'event_type': 'filter',
                          //     'event_category': 'projects',
                          //     'event_name': 'active',
                          //     'event_value': '10',
                          //     'event_params': {
                          //         'user_id': reduxUserId
                          //     }
                          // });
                      }
                    }
                  },
                  {
                    type: 'btn',
                    tooltip: trans('Create new project', language),
                    data: {
                      icon: {
                        Component: PlusIcon,
                        width: 23,
                        height: 23,
                      },
                      onClick: () => {
                          sendSdk('event', {
                              'event_type': 'link',
                              'event_category': 'projects',
                              'event_name': 'create',
                              'event_value': '10',
                              'event_params': {
                                  'user_id': reduxUserId
                              }
                          });
                          navigate('/projects/create/1/');
                      }
                    }
                  }
                ] :
                [{
                    type: 'filter',
                    tooltip: trans('Filter', language),
                    data: {
                      applyCallback: (data:FormData) => {
                          setFilterFormData(data);
                          // _targetadsTag('event', {
                          //     'event_type': 'filter',
                          //     'event_category': 'projects',
                          //     'event_name': 'active',
                          //     'event_value': '10',
                          //     'event_params': {
                          //         'user_id': reduxUserId
                          //     }
                          // });
                      }
                    }
                }]}
              />
            </div>
        {(noProjects || ( reduxProjectsLoaded && tableDataFiltered.length === 0)) && (
          <div className="create">
              {reduxCurrentUser.can_create_project ?
                  <h2 className="title title--black">
                      {trans('Create', language)}<br/>{trans('the first project', language)}
                  </h2> :
                  <h2 className="title title--black">
                      {trans("You don't have any projects yet", language)}
                  </h2>
              }

              {reduxCurrentUser.can_create_project && <Btn
                  text={trans('Create', language)}
                  icon={{
                Component: PlusIcon,
                width: 12,
                height: 12,
              }}
              color='light-blue'
              size='md'
              onClick={() => {
                  sendSdk('event', {
                      'event_type': 'link',
                      'event_category': 'projects',
                      'event_name': 'create',
                      'event_value': '10',
                      'event_params': {
                          'user_id': reduxUserId
                      }
                  });
                  navigate('/projects/create/1/');
              }}
            />}
          </div>
        )}
        {!noProjects && tableDataFiltered.length > 0 && (
          <>
            <HeaderTable isProject={true} onChangeSort={onChangeSortTableCustom}/>
            <Table type='project' rowsData={tableDataFiltered.slice((currentPage-1)*limit, currentPage*limit)}/>
          </>
        )}
        {
          tableDataFiltered.length > 0 && per_page_max > 1 &&
          <Pagination
                sortFront={true}
                onChangeLimitChannel={null}
                limitChannelCustom={limit}
                setLimitChannelCustom={setLimit}
                per_page_max={per_page_max}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                bigData={true}
          />
        }
      </div>
      {(isLoading) &&
        <Loader  />}
    </>
  )
}

export default Projects;
