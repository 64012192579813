import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";

import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";

import '../style.scss';

import TimeFilter from "../../../../../components/ui/TimeFilter";
import Select from "../../../../../components/ui/Select";
import iconPeriod from "../../../../../assets/icons/period.svg";
import {
    getProjectAttributions,
    getProjectGeneral
} from "../../../../../actions/project";
import {getScreenResolution, QueueServer, sendSdk} from "../../../../../utils";
import cx from "classnames";
import DashboardsTable from "../components/Table";
import {trans} from "../../../../../_locales";
import {onLoadingSelectDate} from "../functions";
import {Groups} from "../../../../../data";
import {onChangeGeneralSelect} from "../../../../../components/ui/Select/functions";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {getPages} from "../../../../../components/SideMenu/Role";

const GetIntent = () => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const language = useAppSelector(currentUserLang);
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;

    const [firstLoading, setFirstLoading] = useState(true);

    useEffect(()=> {
        if(projectId && reduxUserId) {
            sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'getintent', 'resolution': getScreenResolution()}})
        }
    }, [projectId, reduxUserId])

    useEffect(() => {
        dispatch(setBreadcrumbs([
            {
              href: '/projects/',
              title: 'Проект',
            },
            {
              href: `/projects/${projectId}/reports/`,
              title: trans('Project reporting', language)
            },
            {
              href: `#`,
              title: 'GetIntent',
              dataset: {
                  'general': {
                      title: trans('General indicators', language),
                      href: `/projects/${projectId}/reports/general`
                  },
                  'ecommerce': {
                      title: trans('E-Commerce Reports', language),
                      href: `/projects/${projectId}/reports/ecommerce`
                  },
                  'user_behavior': {
                      title: trans('User behavior analysis', language),
                      href: `/projects/${projectId}/reports/user_behavior`
                  },
              }
            }
        ]))
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'dashboard_getintent'
        })))
        dispatch(clearInstructions());
        dispatch(getProjectGeneral({currentUserId: reduxUser.id,projectId: Number(projectId)}));
    }, [dispatch, projectId, permissions]);

    const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow');

    //-------Filter--------
    const [periodStart, setPeriodStart] = useState('');
    const [periodEnd, setPeriodEnd] = useState('');
    const [categorize, setCategorize] = useState('day');
    const [selectDate, setSelectDate] = useState<string>(trans('Select the period', language));
    const [serverNoActual, setServerNoActual] = useState(false);
    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
        setServerNoActual(true);
    }

    const datasetCategorize = Groups(language);

    useEffect(() => {
        if(firstLoading) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
              if(r.payload) {
                onLoadingSelectDate({period: r.payload.attribution_period, setPeriodStart, setPeriodEnd, setValue: setSelectDate});
              }
            })
        }

    }, [projectId, reduxUser.id])

    const queue = new QueueServer();

  return (
    <div className={contentClassName}>
        <div className="dashboard-page-filter static">
            <div className="dashboard-page-filter__date">
              <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
            </div>
            <div className="dashboard-page-filter__select">
              <Select selectActive={`${trans('Scale', language)}: ${(datasetCategorize as any)[categorize]}`} icon={iconPeriod} dataset={datasetCategorize} onChangeActive={(res:object)=>onChangeGeneralSelect(res, setCategorize, ()=>setServerNoActual(true))} />
            </div>
        </div>

        <div className="dashboard-page-table">
            <div className="dashboard-page__table">
                <DashboardsTable
                    name={'getintent_fraud_report_custom'}
                    projectId={Number(projectId)}
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    categorize={categorize}
                    serverGroup={true}
                    noActual={serverNoActual}
                    setActual={setServerNoActual}
                    queue={queue}
                    weight={15}
                />
            </div>
        </div>
    </div>
  )
}

export default GetIntent;