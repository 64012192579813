import cls from './style.module.scss'
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {currentUserLang, userId} from "../../../../redux/slices/user";
import {firstDone} from "../../../../redux/slices/projects";
import React, {useEffect, useState} from "react";
import {creatProject, isProject} from "../../../../actions/projects";
import {useNavigate} from "react-router-dom";
import {getScreenResolution, sendSdk, validateUrl} from "../../../../utils";
import Select from "../../../../components/ui/Select";

import './style.scss';
import {trans} from "../../../../_locales";

interface ProjectData {
  name: string;
  site: string;
  currency: 'USD' | 'EUR' | 'RUB';
}

export const Currency:{
  USD: 'Доллары',
  EUR: 'Евро',
  RUB: 'Рубли'
} = {
  USD: 'Доллары',
  EUR: 'Евро',
  RUB: 'Рубли',
}

export const FirstStep = () => {
  const [nameProject, setNameProject] = useState<ProjectData>({name: '', site: '', currency: 'RUB'});
  const [styleUrl, setStyleUrl] = useState(false)
  const [styleName, setStyleName] = useState(false)
  const data = nameProject;
  const dispatch = useAppDispatch();
  const reduxUserId = useAppSelector(userId);
  const language = useAppSelector(currentUserLang);
  const navigate = useNavigate();
  useEffect(()=> {
    if (reduxUserId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'user_id': reduxUserId,
          'page': 'project_create',
          'resolution': getScreenResolution()
        }
      })
    }
  }, [reduxUserId])

  const setNameInput = (e: React.FormEvent<HTMLInputElement>): void => {
    setNameProject({...nameProject, name: e.currentTarget.value})
  }

  const setUrlInput = (e: React.FormEvent<HTMLInputElement>): void => {
    setNameProject({...nameProject, site: e.currentTarget.value})
  }

  const [currencyValue, setCurrencyValue] = useState('RUB')
  const onChangeCurrency = (response: object): void => {
    for(const key in response) {
      changeCurrency(key)
      setCurrencyValue(key)
    }
  }
  const changeCurrency = (value: string): void => {
    if(value === 'RUB' || value === 'USD' || value === 'EUR') {
      setNameProject({...nameProject, currency: value})
    }
  }

  const checkUrl = () => {
    if(nameProject.site && validateUrl(nameProject.site)) {
      // dispatch(isProject())
      return true;
    } else {
      setStyleUrl(true)
      setTimeout(() => {
        setStyleUrl(false)
      }, 3000)
      return false
    }
  }
  const checkName = () => {
    if(nameProject.name) {
      setStyleName(false)
      return true;
    } else {
      setStyleName(true)
      setTimeout(() => {
        setStyleName(false)
      }, 3000)
      return false
    }
  }


  const onSubmit = () => {
    if(nameProject.name && nameProject.site && nameProject.site && checkUrl()) {
      // checkUrl();
      let dataProject = {};
      dispatch(creatProject({data, userId: reduxUserId})).then((r)=> {
        if(r.payload) {
          dataProject = {
            id: r.payload.id,
            currency: r.payload.currency
          }
          dispatch(firstDone({dataProject}))
          navigate('/projects/create/2/')
        }
      })
    } else {
      checkUrl();
      checkName();
    }
  }


  return (
    <>
      <div className={cls.wrapper}>
        <div className={cls.empty}>&nbsp;</div>
        <div className={cls.form}>
          <h3 className={cls.onboard_step}>{trans('Create new project', language)}</h3>
          <h4 className={cls.onboard_desc}>{trans('You start the process of creating a project in the TargetADS system, it will take 4 simple steps. To begin with, fill in the basic parameters of the project', language)}</h4>
          <div className={cls.input_section}>
            <span className={cls.input_label}>{trans('Project name', language)}</span>
            <span className={cls.input_label_text}>{trans("The name of the project will be displayed in the menu, in the reports sent and in the settings. If you have only one project, you don't have to think about the name for a long time", language)}</span>
            <input className={styleName ? cls.input_error : cls.input} type='text' value={nameProject.name} onChange={setNameInput} />
          </div>
          <div className={cls.input_section}>
            <span className={cls.input_label}>{trans('Website address', language)}</span>
            <span className={cls.input_label_text}>{trans('The site address is needed to receive data only from your site, so that no one could distort your data. The address can be entered in the format https://site.com', language)}</span>
            <input className={styleUrl ? cls.input_error : cls.input} type='text' value={nameProject.site} onChange={setUrlInput} placeholder={'http(s)://...'} />
          </div>
          <div className={cls.input_section}>
            <span className={cls.input_label}>{trans('Project currency', language)}</span>
            <span className={cls.input_label_text}>{trans('The currency is needed for the visual display of monetary indicators in reports, we do not convert currencies', language)}</span>
            <div className={cls.select_wrapper + ' project-create__select'}>
              <Select selectActive={(Currency as any)[currencyValue]} dataset={Currency} onChangeActive={onChangeCurrency} />
            </div>
          </div>
        </div>
        <div className={cls.footer}>
          <button
            className={cls.btn}
            type='submit'
            onClick={onSubmit}
          >
            {trans('Next step', language)}
          </button>
        </div>
      </div>
    </>
  );
};
