import {FC, useEffect} from 'react'
import {useAppDispatch, useAppSelector} from '../../redux/hooks'

import {currentUser, currentUserLang, userId} from '../../redux/slices/user'
import {setBreadcrumbs} from "../../redux/slices/breadcrumbs";
import {setSidebar} from "../../redux/slices/sidebar";

import {Profile} from "../../components/SideMenu/Role/general";
import { ProfilePanel } from '../../components'
import {trans} from "../../_locales";
import {getScreenResolution, sendSdk} from "../../utils";
import {clearInstructions} from "../../redux/slices/instructions";

const MainProfile: FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const language = useAppSelector(currentUserLang);

  useEffect(() => {
    dispatch(setBreadcrumbs([
        {
          href: '#',
          title: trans('Profile', language),
          subtitle: user.name
        },
      ]))
    dispatch(setSidebar(Profile(language)));
    dispatch(clearInstructions());
  }, [dispatch, user.name]);

  useEffect(()=> {
      if (reduxUserId) {
          sendSdk('page_view', {
              'user': {
                  'uid': reduxUserId
              },
              'event_params': {
                  'user_id': reduxUserId,
                  'page': 'profile',
                  'resolution': getScreenResolution()
              }
          })
      }
  }, [reduxUserId])

  return (
      <ProfilePanel className='page-content__content-wrapper' />
  );
}

export default MainProfile;