import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReportUpdate from "../../../../../components/ReportsUpdate";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {getScreenResolution, sendSdk} from "../../../../../utils";
import {setSidebar} from "../../../../../redux/slices/sidebar";
import {getPages} from "../../../../../components/SideMenu/Role";

const ReportsEdit = () => {
    const { projectId, reportId } = useParams();
    const reduxUserId = useAppSelector(userId);
    const reduxUser = useAppSelector(currentUser);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const language = useAppSelector(currentUserLang);
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    useEffect(()=> {
        if (projectId && reduxUserId && reportId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'custom_reports_edit',
                    'custom_report_id': reportId,
                    'resolution': getScreenResolution()
                }
            })
        }
        const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('custom_reports');
        const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('custom_reports');
        const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('custom_reports');
        const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('custom_reports');

        if(permissions && !isEdit) {
            navigate(`/projects/${projectId}/custom-reports/`)
        }
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'custom_reports'
        })))
    }, [projectId, reduxUserId, reportId, permissions])
    return (
        <>
            <ReportUpdate reportType={'edit'} projectId={projectId} reportId={reportId}/>
        </>
    );
};


export default ReportsEdit;