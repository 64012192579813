import {FC, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../../redux/hooks'

import {currentUser, currentUserLang, userId} from '../../../../redux/slices/user'
import {
  campaignsData,
  campaignsLoaded,
  errorValue,
  getIsLoading,
  getUploadIsLoading,
  removeError
} from '../../../../redux/slices/media'
import {closeModal, ModalName, openModal} from '../../../../redux/slices/modals'
import {projectGeneral} from '../../../../redux/slices/project'
import {setBreadcrumbs} from "../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../redux/slices/sidebar";

import {
  deleteCampaign,
  deleteMultipleCampaign,
  downloadCampaignsData, downloadCampaignsTemplateXlsx,
  downloadPixelsCsv, getCampaignsGenerals,
  uploadMediaCsv
} from '../../../../actions/media'
import {getProjectGeneral} from '../../../../actions/project'
import {fetchSources, getBaseSources} from '../../../../actions/source'


import {Btn, SearchForm} from '../../../../ui'
import {itemsFilterFunction, useShowError} from '../../../../helpers'

import {HeaderTable, Loader, Options, Table} from '../../../../components'
import Empty from "../../../../components/Empty";
import Pagination from "../../../../components/ui/Pagination";
import {getCountPage} from "../../../../components/Tables/TablePagination/functions";

import {ReactComponent as ArrowUpIcon} from '../../../../assets/icons/icon-arrow-up.svg'
import {ReactComponent as ArrowDownIcon} from '../../../../assets/icons/icon-arrow-down.svg'
import {ReactComponent as PlusIcon} from '../../../../assets/icons/icon-plus.svg'
import {ReactComponent as IconDatabase} from '../../../../assets/icons/icon-database.svg'

import {ICampaigns} from './interfaces.d'
import cx from 'classnames'
import './style.scss';
import {trans} from "../../../../_locales";
import {sources} from "../../../../redux/slices/sources";
import {getEvents} from "../../../../actions/events";
import {getTags} from "../../../../actions/tags";
import {getScreenResolution, getValueByNestedKey, sendSdk} from "../../../../utils";
import MultipleActions from "../../../../components/MultipleActions";
import {deleteMultiplePlacement} from "../../../../actions/placements";
import {tags} from "../../../../redux/slices/tags";
import {DefaultObject} from "../../../../interfaces/common.d";
import {clearInstructions, setInstructions} from "../../../../redux/slices/instructions";
import {getPages} from "../../../../components/SideMenu/Role";

const Campaigns: FC<ICampaigns> = ({ noCampaigns, withMocks }) => {
  const reduxUser =  useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const navigate = useNavigate();
  const reduxCampaigns = useAppSelector(campaignsData);
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const isLoading = useAppSelector(getIsLoading);
  const uploadIsLoading = useAppSelector(getUploadIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);
  const reduxSources = useAppSelector(sources);
  const reduxTags = useAppSelector(tags);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('campaigns');
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('campaigns');
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('campaigns');
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('campaigns');

  if(permissions && !isView) {
    navigate(`/projects/${projectId}/`)
  }

  const reduxUserIdRef = useRef<number | null>(null);

  useEffect(()=> {
    if(projectId && reduxUserId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
          'page': 'campaign',
          'resolution': getScreenResolution()
        }
      });
    }
  }, [projectId, reduxUserId])

  useEffect(() => {
    dispatch(setBreadcrumbs([
      {
        href: '/projects',
        title: 'Проект',
        subtitle: reduxCurrentProject?.name
      },
      {
        href: '#',
        title: trans('Campaigns', language),
      },
    ]))
    dispatch(setSidebar(getPages({
        projectId: Number(projectId),
        access: permissions && permissions['can_view'],
        language,
        active: 'campaigns'
    })))
    dispatch(clearInstructions());
    dispatch(setInstructions({
        title: trans('Instruction', language),
        cb: ()=> {
            dispatch(openModal({
                name: ModalName.DOCS_CREATE_CAMPAIGN_FULL,
                data: {}
            }))
        }
    }))
  }, [dispatch, reduxCurrentProject?.name]);

  useEffect(() => {
    if (reduxUserId && projectId && reduxUserId !== reduxUserIdRef.current) {
      reduxUserIdRef.current = reduxUserId;
      dispatch(downloadCampaignsData({projectId: +projectId}));
      dispatch(getProjectGeneral({currentUserId: reduxUserId, projectId: +projectId}));
      dispatch(fetchSources({projectId: +projectId}))
      dispatch(getEvents({projectId, calcAttr: true}))
      dispatch(getTags({projectId: +projectId}))
      dispatch(getBaseSources({projectId: +projectId}))
    }
  }, [reduxUserId, projectId, dispatch]);

  useEffect(() => {
    const header = document.querySelector('header');
    const body = document.querySelector('body');
    const content = document.querySelector('.page-content');
    header && header.classList.remove('hide');
    content && content.classList.remove('stretch');
    body && body.classList.remove('no-scroll');
  }, []);

  useEffect(() => {
    if (uploadIsLoading) {
      dispatch(openModal({
        name: ModalName.INFORMATION,
        data: {
          title: 'Loading data..',
          description: 'Be patient, we’re connecting your database, it may take up to 1 hour',
          icon: {
            Component: IconDatabase,
            width: 23,
            height: 23
          },
          mod: 'loader'
        }
      }))
    }

    return () => {
      dispatch(closeModal(ModalName.INFORMATION))
    };
  }, [uploadIsLoading, dispatch]);

  useShowError(errorMessage as string, removeError);

  const reduxCampaignsData = useAppSelector(campaignsData);
  const reduxCampaignsDataForProject = reduxCampaignsData[projectId as string];
  const reduxCampaignsLoaded =  useAppSelector(campaignsLoaded);
  const tableData = (reduxCampaignsDataForProject || [])
  const [dataWrapper, setDataWrapper] = useState<Array<any>>([...tableData]);

  useEffect(()=> {
    setDataWrapper([...tableData])
  },[reduxCampaignsDataForProject])

  const [searchValue, setSearchValue] = useState<string>('');
  const [filterFormData, setFilterFormData] = useState<FormData>(new FormData);

  const tableDataFiltered = dataWrapper.filter((item) => {
    return itemsFilterFunction({
      formData: filterFormData,
      statusField: item.status,
      dateField: item.created_at,
      nameField: item.name,
      searchValue: searchValue,
      reduxSources,
      purchaseType: item.purchase_type,
      sourceId: item.source_id,
      type: 'campaigns',
      tags: item.tags
    });
  });

  const onFileChosen = (evt: any) => {
    const formData = new FormData();
    formData.append('csv_file', evt.currentTarget.files[0]);
    if (reduxUserId && projectId) {
      dispatch(uploadMediaCsv({
        data: formData,
        userId: reduxUserId,
        projectId: +projectId
      }));
    }
  };

  const downloadHandler = (mode: 'all' | 'active' | number[]) => {
    if (reduxUserId && projectId) {
      dispatch(downloadPixelsCsv({
        mode: mode,
        userId: reduxUserId,
        projectId: +projectId
      }))
    }
  }

  const downloadTemplatesHandler = (mode: 'all' | 'active' | number[]) => {
    if (reduxUserId && projectId) {
      dispatch(downloadCampaignsTemplateXlsx({
        mode: mode,
        userId: reduxUserId,
        projectId: +projectId
      }))
    }
  }

  const getChosenIds = () => {
    if(projectId && Object.keys(campaignsIsChecked).length) {
      const currentCampaigns = reduxCampaigns && reduxCampaigns[projectId] ? reduxCampaigns[projectId] : [];
      const ids = currentCampaigns.map(item => item.id);
      const temp:any[] = [];
      Object.keys(campaignsIsChecked).map((item)=> {
        if(campaignsIsChecked[item] && ids.includes(+item)) temp.push(item);
      })
      return temp;
    }

    return Array.from(document.querySelectorAll('input[name="row-choice"]:checked')).map((input: any) => input.value);
  };

  const [chosenLength, setChosenLength] = useState<number>(0);
  const onChoice = (value:any) => {
    setCampaignIsChecked(value);
    const count = getChosenIds().length;
    setChosenLength(count);
  }
  const updatedChosen = () => {
    const list = getChosenIds();
    const temp:DefaultObject<boolean> = {};
    list.map((item)=> {
      temp[item] = true;
    })
    setCampaignIsChecked(temp);
    setChosenLength(list.length);
  }

  useEffect(()=> {
    updatedChosen();
  }, [reduxCampaigns])

  const contentClassName = cx('page-content__content', {'empty': noCampaigns || tableData.length === 0});

  const titleName = ['','','name',`stats.impression`,'stats.clicks','stats.reach','stats.spent','stats.leads','stats.postview_cpa','created_at']
  const onChangeSortTableCustom = (event:any, index:number) => {
        const element = event.target.closest('th');
        const tempData = [...dataWrapper];

        if(element.classList.contains('ascending')) {
          const tempData = [...dataWrapper];
          setDataWrapper(tempData.sort(function (a, b) {
              const first = getValueByNestedKey(a, titleName[index]),
                  second = getValueByNestedKey(b, titleName[index]);
              if (first < second) {
                return 1;
              }
              if (first > second) {
                return -1;
              }
              return 0;
          }));
          element.className = 'descending';
          return;
        }
        if(element.classList.contains('descending')) {
          element.className = '';
          setDataWrapper([...tableData]);
          return;
        }
        setDataWrapper(tempData.sort(function (a, b) {
          const first = getValueByNestedKey(a, titleName[index]),
                second = getValueByNestedKey(b, titleName[index]);
          if (first > second) {
            return 1;
          }
          if (first < second) {
            return -1;
          }
          return 0;
        }));
        Array.from(element.parentNode.childNodes).map((item:any) => (
            item.className = ''
        ))
        element.className = 'ascending';
  }

  const [limit, setLimit] = useState(30);
  const per_page_max = getCountPage(tableDataFiltered.length, limit);
  const [currentPage, setCurrentPage] = useState(1);

  const [allChecked, setAllChecked] = useState(false);
  const [campaignsIsChecked, setCampaignIsChecked] = useState<any>({})

  const onDeleteCampaigns = (userId:any, projectId:any, ids:Array<number>) => {
    dispatch(openModal({
      name: ModalName.CONFIRM_DELETE,
      data: {
        text: trans('Confirm the deletion of the campaigns', language),
        onConfirm: () => deleteChoicesCampaign(userId, projectId, ids)
      }
    }))
  }
  const deleteChoicesCampaign = (userId:number, projectId:number, ids:Array<number> = []) => {
    sendSdk('event', {
      'event_type': 'delete',
      'event_category': 'campaign',
      'event_name': 'action',
      'event_value': '10',
      'event_params': {
        'project_id': projectId,
        'user_id': userId,
        'campaign_id': ids.join(',')
      }
    });
    updatedChosen();
    dispatch(deleteMultipleCampaign({
        projectId: projectId,
        campaignIds: ids
    }))
  }

  return (
    // <Base headerData={headerData} sidebarData={sidebarData} border={true}>
      <>
      <div className={contentClassName}>
        {(noCampaigns || ( reduxCampaignsLoaded && tableData.length === 0)) && (
          <div className="create">
            <h2 className="title title--black">
              {trans('Campaignies', language)}<br/>{trans('not found', language)}
            </h2>
            {isCreate && <Btn
              text={trans('Create', language)}
              icon={{
                Component: PlusIcon,
                width: 12,
                height: 12,
              }}
              color='light-blue'
              size='md'
              onClick={() => {
                dispatch(openModal({
                  name: ModalName.NEW_CAMPAIGN,
                  data: {
                    userId: reduxUserId,
                    projectId: projectId ? +projectId : -1
                  }
                }));
                sendSdk('event', {
                  'event_type': 'open',
                  'event_category': 'campaign',
                  'event_name': 'create',
                  'event_value': '10',
                  'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                  }
                });
              }}
              disabled={!isCreate}
            />}
            <Btn
              className={'campaigns__link'}
              text={trans('Upload Excel', language)}
              color='light-blue'
              onFileChosen={onFileChosen}
              isDownloadBtn={true}
              fileType={'xlsx'}
              disabled={!isCreate}
            />
          </div>
        )}
        {!noCampaigns && tableData.length > 0 && (
          <>
            <div className="page-content__header">
              <SearchForm
                value={searchValue}
                onChangeCb={(value) => {
                  setSearchValue(value);
                }}
              />
              {
                (isEdit || isCreate || isDelete) ?
                <Options
                items={[
                  {
                    type: 'btn',
                    tooltip: trans('Upload', language),
                    data: {
                      isDownloadBtn: true,
                      icon: {
                        Component: ArrowUpIcon,
                        width: 25,
                        height: 25,
                      },
                      onFileChosen: onFileChosen,
                      fileType:'xlsx'
                    }
                  },
                  {
                    type: 'popup',
                    tooltip: trans('Download', language),
                    data: {
                      btn: {
                        icon: {
                          Component: ArrowDownIcon,
                          width: 16,
                          height: 18
                        },
                        isIconBtn: true,
                        size: 'lg'
                      },
                      isCenter: true,
                      dropdownList: {
                        items: [
                          {
                            text: trans('Download selected', language),
                            onClick: () => {
                              downloadHandler(getChosenIds());
                              sendSdk('event', {
                                'event_type': 'download',
                                'event_category': 'campaign',
                                'event_name': 'selected',
                                'event_value': '10',
                                'event_params': {
                                  'project_id': projectId,
                                  'user_id': reduxUserId,
                                }
                              });
                            }
                          },
                          {
                            text: trans('Download active', language),
                            onClick: () => {
                              downloadHandler('active');
                              sendSdk('event', {
                                'event_type': 'download',
                                'event_category': 'campaign',
                                'event_name': 'active',
                                'event_value': '10',
                                'event_params': {
                                  'project_id': projectId,
                                  'user_id': reduxUserId,
                                }
                              });
                            }
                          },
                          {
                            text: trans('Download all', language),
                            onClick: () => {
                              downloadHandler('all');
                              sendSdk('event', {
                                'event_type': 'download',
                                'event_category': 'campaign',
                                'event_name': 'all',
                                'event_value': '10',
                                'event_params': {
                                  'project_id': projectId,
                                  'user_id': reduxUserId,
                                }
                              });
                            }
                          },
                        ]
                      },
                    }
                  },
                  {
                    type: 'filter',
                    tooltip: trans('Filter', language),
                    data: {
                      applyCallback: (data:FormData) => {
                        setFilterFormData(data);
                        // _targetadsTag('event', {
                        //   'event_type': 'filter',
                        //   'event_category': 'campaign',
                        //   'event_name': 'active',
                        //   'event_value': '10',
                        //   'event_params': {
                        //     'project_id': projectId,
                        //     'user_id': reduxUserId,
                        //   }
                        // });
                      },
                      type: 'campaigns',
                      projectId: Number(projectId),
                    }
                  },
                  {
                    type: 'btn',
                    tooltip: trans('Create new campaign', language),
                    data: {
                      icon: {
                        Component: PlusIcon,
                        width: 23,
                        height: 23,
                      },
                      onClick: () => {
                        dispatch(openModal({
                          name: ModalName.NEW_CAMPAIGN,
                          data: {
                            userId: reduxUserId,
                            projectId: projectId ? +projectId : -1
                          }
                        }))
                        sendSdk('event', {
                          'event_type': 'open',
                          'event_category': 'campaign',
                          'event_name': 'create',
                          'event_value': '10',
                          'event_params': {
                            'project_id': projectId,
                            'user_id': reduxUserId,
                          }
                        });
                      }
                    }
                  }
                ]}
                />
                    : isCreate ?
                <Options
                items={[
                  {
                    type: 'popup',
                    tooltip: trans('Download', language),
                    data: {
                      btn: {
                        icon: {
                          Component: ArrowDownIcon,
                          width: 16,
                          height: 18
                        },
                        isIconBtn: true,
                        size: 'lg'
                      },
                      isCenter: true,
                      dropdownList: {
                        items: [
                          {
                            text: trans('Download selected', language),
                            onClick: () => {
                              downloadHandler(getChosenIds());
                              sendSdk('event', {
                                'event_type': 'download',
                                'event_category': 'campaign',
                                'event_name': 'selected',
                                'event_value': '10',
                                'event_params': {
                                  'project_id': projectId,
                                  'user_id': reduxUserId,
                                }
                              });
                            }
                          },
                          {
                            text: trans('Download active', language),
                            onClick: () => {
                              downloadHandler('active');
                              sendSdk('event', {
                                'event_type': 'download',
                                'event_category': 'campaign',
                                'event_name': 'active',
                                'event_value': '10',
                                'event_params': {
                                  'project_id': projectId,
                                  'user_id': reduxUserId,
                                }
                              });
                            }
                          },
                          {
                            text: trans('Download all', language),
                            onClick: () => {
                              downloadHandler('all');
                              sendSdk('event', {
                                'event_type': 'download',
                                'event_category': 'campaign',
                                'event_name': 'all',
                                'event_value': '10',
                                'event_params': {
                                  'project_id': projectId,
                                  'user_id': reduxUserId,
                                }
                              });
                            }
                          },
                        ]
                      },
                    }
                  },
                  {
                    type: 'filter',
                    tooltip: trans('Filter', language),
                    data: {
                      applyCallback: (data:FormData) => {
                        setFilterFormData(data);
                        // _targetadsTag('event', {
                        //   'event_type': 'filter',
                        //   'event_category': 'campaign',
                        //   'event_name': 'active',
                        //   'event_value': '10',
                        //   'event_params': {
                        //     'project_id': projectId,
                        //     'user_id': reduxUserId,
                        //   }
                        // });
                      },
                      type: 'campaigns',
                      projectId: Number(projectId)
                    }
                  },
                    {
                    type: 'btn',
                    tooltip: trans('Create new campaign', language),
                    data: {
                      icon: {
                        Component: PlusIcon,
                        width: 23,
                        height: 23,
                      },
                      onClick: () => {
                        dispatch(openModal({
                          name: ModalName.NEW_CAMPAIGN,
                          data: {
                            userId: reduxUserId,
                            projectId: projectId ? +projectId : -1
                          }
                        }))
                        sendSdk('event', {
                          'event_type': 'open',
                          'event_category': 'campaign',
                          'event_name': 'create',
                          'event_value': '10',
                          'event_params': {
                            'project_id': projectId,
                            'user_id': reduxUserId,
                          }
                        });
                      }
                    }
                  }
                ]}
                /> :
                <Options
                items={[
                  {
                    type: 'popup',
                    tooltip: trans('Download', language),
                    data: {
                      btn: {
                        icon: {
                          Component: ArrowDownIcon,
                          width: 16,
                          height: 18
                        },
                        isIconBtn: true,
                        size: 'lg'
                      },
                      isCenter: true,
                      dropdownList: {
                        items: [
                          {
                            text: trans('Download selected', language),
                            onClick: () => {
                              downloadHandler(getChosenIds());
                              sendSdk('event', {
                                'event_type': 'download',
                                'event_category': 'campaign',
                                'event_name': 'selected',
                                'event_value': '10',
                                'event_params': {
                                  'project_id': projectId,
                                  'user_id': reduxUserId,
                                }
                              });
                            }
                          },
                          {
                            text: trans('Download active', language),
                            onClick: () => {
                              downloadHandler('active');
                              sendSdk('event', {
                                'event_type': 'download',
                                'event_category': 'campaign',
                                'event_name': 'active',
                                'event_value': '10',
                                'event_params': {
                                  'project_id': projectId,
                                  'user_id': reduxUserId,
                                }
                              });
                            }
                          },
                          {
                            text: trans('Download all', language),
                            onClick: () => {
                              downloadHandler('all');
                              sendSdk('event', {
                                'event_type': 'download',
                                'event_category': 'campaign',
                                'event_name': 'all',
                                'event_value': '10',
                                'event_params': {
                                  'project_id': projectId,
                                  'user_id': reduxUserId,
                                }
                              });
                            }
                          },
                        ]
                      },
                    }
                  },
                  {
                    type: 'filter',
                    tooltip: trans('Filter', language),
                    data: {
                      applyCallback: (data:FormData) => {
                        setFilterFormData(data);
                        // _targetadsTag('event', {
                        //   'event_type': 'filter',
                        //   'event_category': 'campaign',
                        //   'event_name': 'active',
                        //   'event_value': '10',
                        //   'event_params': {
                        //     'project_id': projectId,
                        //     'user_id': reduxUserId,
                        //   }
                        // });
                      },
                      type: 'campaigns',
                      projectId: Number(projectId)
                    }
                  }
                ]}
                />
              }
            </div>


            {
              tableDataFiltered.length > 0 ?
                  <>
                    <HeaderTable onChangeSort={onChangeSortTableCustom} allChecked={allChecked} onAllChecked={(value:boolean=true)=>{
                      setAllChecked(value);
                      const temp:any = {};
                      let count = 0;
                      tableDataFiltered.map((item:any)=> {
                        temp[item.id] = value;
                        if(value) count++;
                      })
                      setCampaignIsChecked(temp)
                      setChosenLength(count);
                    }}/>
                    <Table type='campaign' rowsData={tableDataFiltered.slice((currentPage-1)*limit, currentPage*limit)} allChecked={allChecked} onChoice={onChoice} chosen={campaignsIsChecked}/>
                  </>
                   :
                    <div className="create create_in-block">
                      <div>
                        <Empty title={trans('Nothing found', language)} />
                      </div>
                  
                      <Btn
                        text={trans('Create', language)}
                        icon={{
                          Component: PlusIcon,
                          width: 12,
                          height: 12,
                        }}
                        color='light-blue'
                        size='md'
                        onClick={() => {
                          dispatch(openModal({
                            name: ModalName.NEW_CAMPAIGN,
                            data: {
                              userId: reduxUserId,
                              projectId: projectId ? +projectId : -1
                            }
                          }));
                          sendSdk('event', {
                            'event_type': 'open',
                            'event_category': 'campaign',
                            'event_name': 'create',
                            'event_value': '10',
                            'event_params': {
                              'project_id': projectId,
                              'user_id': reduxUserId,
                            }
                          });
                        }}
                        disabled={!isCreate}
                      />
                      <Btn
                        className={'campaigns__link'}
                        text={trans('Upload Excel', language)}
                        color='light-blue'
                        onFileChosen={onFileChosen}
                        isDownloadBtn={true}
                        fileType={'xlsx'}
                        disabled={!isCreate}
                      />
                    </div>
            }
            {
              tableDataFiltered.length > 0 && per_page_max > 1 &&
              <Pagination
                    sortFront={true}
                    onChangeLimitChannel={null}
                    limitChannelCustom={limit}
                    setLimitChannelCustom={setLimit}
                    per_page_max={per_page_max}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    bigData={true}
              />
            }
            {
              chosenLength > 1 && <MultipleActions
                  count={chosenLength}
                  type={'campaigns'}
                  onChange={()=>{
                    dispatch(getCampaignsGenerals({projectId: projectId ? +projectId : -1, campaignIds: getChosenIds()})).then((r)=> {
                      let initialValues:any = {};
                      if(r.payload) {
                        initialValues = r.payload;
                      }
                      dispatch(openModal({
                        name: ModalName.EDIT_MULTI_CAMPAIGNS,
                        data: {
                          userId: reduxUserId,
                          projectId: projectId ? +projectId : -1,
                          ids: getChosenIds(),
                          initialValues: initialValues
                        }
                      }))
                    })
                  }}
                  onDownload={()=>downloadHandler(getChosenIds())}
                  onDownloadTemplate={()=>downloadTemplatesHandler(getChosenIds())}
                  onDelete={()=>onDeleteCampaigns(reduxUserId, projectId, getChosenIds())}
                  onReset={()=> {
                    const temp:any = {};
                    tableDataFiltered.map((item:any)=> {
                      temp[item.id] = false;
                    })
                    setCampaignIsChecked(temp)
                    setChosenLength(0);
                    setAllChecked(false);
                  }}
                  isEdit={isEdit}
                  isDelete={false}
              />
            }
          </>
        )}
      </div>
      {(isLoading) &&
        <Loader inBlock={true} />}
      </>
  )
}

export default Campaigns;
