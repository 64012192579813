import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import UploadContent from "../../../../../components/UploadContent";
import {uploadCsv} from "../../../../../actions/upload";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {Loader} from "../../../../../components";
import './style.scss';
import {trans} from "../../../../../_locales";
import {currentUserLang, userId} from "../../../../../redux/slices/user";
import {updateUploadedFiles} from "../functions";
import {getScreenResolution, sendSdk} from "../../../../../utils";

const UploadDefault = () => {
    const dispatch = useAppDispatch();
    const {projectId} = useParams();
    const language = useAppSelector(currentUserLang);
    const reduxUserId = useAppSelector(userId);

    useEffect(()=> {
        if (projectId && reduxUserId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'upload_medium_expenses',
                    'resolution': getScreenResolution()
                }
            })
        }
    }, [projectId, reduxUserId])

    const [isLoading , setIsLoading] = useState(false);
    const data = {
        title: trans('Upload expenses for media campaigns', language),
        subtitle: trans('If you import expenses from advertising systems that are in the list of integrations, we recommend setting up automatic import. For everything else there is a CSV :)', language),
        info: [
            {
                title: trans('The file must contain the following fields', language),
                dataset: [
                    trans('Date (we upload expenses by day)', language),
                    trans('Campaign name (must match the name of the created or imported campaigns)', language),
                    trans('The name of the creative (must match the name of the created or imported creatives)', language),
                    trans('Cost with VAT', language)
                ]
            }
        ],
    }
    const handleUploadedFiles = (files:any, scale:string) => {
        if(projectId) {
            updateUploadedFiles(files, scale, 'medium_expenses', +projectId, setIsLoading, dispatch, reduxUserId, language);
        }
    }
        // setNewUserInfo({ ...newUserInfo, profileImages: files });
    return(
        <div className='upload__wrapper'>
            <UploadContent
                title={data.title}
                subtitle={data.subtitle}
                info={data.info}
                updateFilesCb={handleUploadedFiles}
                multiple={false}
                example='https://cdn.targetads.io/files/cost_media.xlsx'
            />
            {(isLoading) &&
                <Loader inBlock={true}/>}
        </div>
    )
}

export default UploadDefault;