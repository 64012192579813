import React, {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {currentUser, currentUserLang, userId} from "../../../../redux/slices/user";
import {getIsLoading} from "../../../../redux/slices/projects";
import {setBreadcrumbs} from "../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../redux/slices/sidebar";

import {ReactComponent as PlusIcon} from '../../../../assets/icons/icon-plus.svg'

import {Btn, SearchForm} from "../../../../ui";

import Loader from "../../../../components/Loader";
import Empty from "../../../../components/Empty";
import Pagination from "../../../../components/ui/Pagination";

import {getCountPage} from "../../../../components/Tables/TablePagination/functions";

import './style.scss';
import {trans} from "../../../../_locales";
import {Popup} from "../../../../components";
import { ReactComponent as DotsIcon } from '../../../../assets/icons/icon-dots.svg'
import {deleteReport, getReports, updateReport, updateReportStatus} from "../../../../actions/reports";
import {reportsData} from "../../../../redux/slices/reports";
import {Periods} from "../../../../data";
import {getScreenResolution, sendSdk} from "../../../../utils";
import {clearInstructions, setInstructions} from "../../../../redux/slices/instructions";
import {ModalName, openModal} from "../../../../redux/slices/modals";
import {getPages} from "../../../../components/SideMenu/Role";

const Reports = () => {
    const reduxReports = useAppSelector(reportsData);
    const reduxUser = useAppSelector(currentUser);
    const reduxUserId = useAppSelector(userId);
    const isLoading = useAppSelector(getIsLoading);
    const language = useAppSelector(currentUserLang);
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const { projectId } = useParams();
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('custom_reports');
    const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('custom_reports');
    const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('custom_reports');
    const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('custom_reports');

    if(permissions && !isView) {
        navigate(`/projects/${projectId}/`)
    }

    useEffect(()=> {
        if (projectId && reduxUserId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'custom_reports',
                    'resolution': getScreenResolution()
                }
            })
        }
    }, [projectId, reduxUserId])

    useEffect(() => {
        dispatch(clearInstructions());
        dispatch(setBreadcrumbs([
            {
                href: '/projects/',
                title: 'Проект',
            },
            {
                href: `/projects/${projectId}/custom-reports/`,
                title: trans('Custom reports', language)
            }
        ]))
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'custom_reports'
        })))
        dispatch(setInstructions({
            title: trans('Instruction', language),
            cb: ()=> {
                dispatch(openModal({
                    name: ModalName.DOCS_CREATE_CUSTOM_DASHBOARD,
                    data: {}
                }))
            }
        }))
    }, [dispatch, projectId, permissions]);

    useEffect(() => {
        const header = document.querySelector('header');
        const body = document.querySelector('body');
        const content = document.querySelector('.page-content');
        header && header.classList.remove('hide');
        content && content.classList.remove('stretch');
        body && body.classList.remove('no-scroll');
    }, []);

    const [dataWrapper, setDataWrapper] = useState<Array<any>>([]);
    const [limitChannelCustom, setLimitChannelCustom] = useState(30);

    useEffect(() => {
        dispatch(getReports({projectId})).then((r) => {
            if (r.payload && r.payload !== 'Not Found') {
                setDataWrapper([...r.payload]);
            }
        });
    }, [dispatch]);
    useEffect(() => {
        if (reduxReports.length > 0) {
            setDataWrapper([...reduxReports]);
        }
    }, [reduxReports])
    const per_page_max = getCountPage(dataWrapper.length, limitChannelCustom);
    const [searchValue, setSearchValue] = useState<string>('');
    const [title, setTitle] = useState<Array<any>>([
        trans('Naming', language),
        trans('Period', language),
        trans('Sending status', language),
        trans('Author', language),
        trans('Creation date', language),
        `${trans('Total', language)}: ${reduxReports.length}`,
    ])

    useEffect(()=> {
        setTitle([
            trans('Naming', language),
            trans('Period', language),
            trans('Sending status', language),
            trans('Author', language),
            trans('Creation date', language),
            `${trans('Total', language)}: ${reduxReports.length}`,
        ])
    }, [reduxReports, language])

    const titleName = ['name', 'period', '', 'author_name', 'created_at', 'total']
    const [currentPage, setCurrentPage] = useState(1);
    const pageItems = [];
    for(let i = 1; i <= per_page_max; i++) {
        pageItems.push(i);
    }

    const onChangeValueSearch= (value:string) => {
        if (value === '') {
            setDataWrapper(reduxReports);
            setSearchValue('')
            return;
        }
        const regex = new RegExp(value.toLowerCase());
        setSearchValue(value);
        setDataWrapper(reduxReports.filter((item: any) => regex.test(item[titleName[0]].toLowerCase())));
    }
    const onChangeSortTableCustom = (event:any, index:number) => {
        const element = event.target.closest('th');
        const tempData = [...dataWrapper];

        setDataWrapper(tempData.sort(function (a, b) {
            if (a[titleName[index]] > b[titleName[index]]) {
                return 1;
            }
            if (a[titleName[index]] < b[titleName[index]]) {
                return -1;
            }
            return 0;
        }));
        if (element.classList.contains('ascending')) {
            const tempData = [...dataWrapper];
            setDataWrapper(tempData.sort(function (a, b) {
                if (a[titleName[index]] < b[titleName[index]]) {
                    return 1;
                }
                if (a[titleName[index]] > b[titleName[index]]) {
                    return -1;
                }
                return 0;
            }));
            element.className = 'descending';
            return;
        }
        if (element.classList.contains('descending')) {
            element.className = '';
            const tempData = [...dataWrapper];
            setDataWrapper(tempData.sort(function (a, b) {
                if (a[titleName[0]] < b[titleName[0]]) {
                    return 1;
                }
                if (a[titleName[0]] > b[titleName[0]]) {
                    return -1;
                }
                return 0;
            }));
            return;
        }
        Array.from(element.parentNode.childNodes).map((item: any) => (
            item.className = ''
        ))
        element.className = 'ascending';
    }

    interface IThead {
        title: Array<any>
        onChangeSort: any
    }

    const Thead:FC<IThead> = ({
      title
    }) => {
        return (
            <div>
                <div className='reports-table-head'>
                    {
                        title.map((item: string, index: number) => (
                            <div key={index} className='reports-table-head__item'>
                                <div className="reports-table-head__title">
                                    <span>{item}</span>
                                    <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.6 1C5.6 0.668629 5.33137 0.4 5 0.4C4.66863 0.4 4.4 0.668629 4.4 1L5.6 1ZM4.57574 11.4243C4.81005 11.6586 5.18995 11.6586 5.42426 11.4243L9.24264 7.60589C9.47696 7.37157 9.47696 6.99167 9.24264 6.75736C9.00833 6.52304 8.62843 6.52304 8.39411 6.75736L5 10.1515L1.60589 6.75736C1.37157 6.52304 0.991674 6.52304 0.757359 6.75736C0.523045 6.99167 0.523045 7.37157 0.757359 7.60589L4.57574 11.4243ZM4.4 1L4.4 11L5.6 11L5.6 1L4.4 1Z"
                                            fill="transparent"/>
                                    </svg>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    interface ITbody {
        titles?: Array<any>
        projectId?: number | string
        data: any
    }

    const Tbody:FC<ITbody> = ({
      projectId,
      data,
    }) => {
        const [addFlag] = useState(false);
        const getOptionsByPermissions = (status: string, frequency_type?:string) => {
            const result:any[] = [];
            if(status === 'draft') {
                if(isCreate || data.author_id === reduxUser.id) {
                    result.push({
                        text: trans('Create based on', language),
                        onClick: () => {
                            navigate(`/projects/${projectId}/custom-reports/${data.id}/create/`);
                        }
                    })
                }
                if(isEdit || data.author_id === reduxUser.id) {
                    result.push({
                        text: trans('Edit', language),
                        onClick: () => {
                            navigate(`/projects/${projectId}/custom-reports/${data.id}/edit/`);
                        }
                    })
                }
                if(isDelete || data.author_id === reduxUser.id) {
                    result.push({
                        text: trans('Delete', language),
                        onClick: () => {
                            dispatch(deleteReport({projectId, reportId: data.id}));
                            sendSdk('event', {
                                'event_type': 'delete',
                                'event_category': 'custom_reports',
                                'event_name': 'action',
                                'event_value': '10',
                                'event_params': {
                                    'project_id': projectId,
                                    'user_id': reduxUserId,
                                    'report_id': data.id
                                }
                            });
                        }
                    })
                }
            } else if(status === 'pause' || (status === 'active' && frequency_type !== 'one_time')) {
                if(isCreate || data.author_id === reduxUser.id) {
                    result.push({
                        text: trans('Create based on', language),
                        onClick: () => {
                            navigate(`/projects/${projectId}/custom-reports/${data.id}/create/`);
                        }
                    })
                }
                if(isEdit || data.author_id === reduxUser.id) {
                    result.push({
                        text: data.status === 'pause' ? trans('Run', language) : trans('Stop', language),
                        onClick: () => {
                            if (data.status === 'pause') {
                                dispatch(updateReportStatus({status: 'active', reportId: data.id, projectId}));
                            }
                            if (data.status === 'active') {
                                dispatch(updateReportStatus({status: 'pause', reportId: data.id, projectId}));
                            }
                            sendSdk('event', {
                                'event_type': 'edit',
                                'event_category': 'custom_reports',
                                'event_name': data.status === 'active' ? 'stop' : 'start',
                                'event_value': '10',
                                'event_params': {
                                    'project_id': projectId,
                                    'user_id': reduxUserId,
                                    'report_id': data.id
                                }
                            });
                        }
                    })
                    result.push({
                        text: trans('Edit', language),
                        onClick: () => {
                            navigate(`/projects/${projectId}/custom-reports/${data.id}/edit/`);
                        }
                    })
                }
                if(isDelete || data.author_id === reduxUser.id) {
                    result.push({
                        text: trans('Delete', language),
                        onClick: () => {
                            dispatch(deleteReport({projectId, reportId: data.id}));
                            sendSdk('event', {
                                'event_type': 'delete',
                                'event_category': 'custom_reports',
                                'event_name': 'action',
                                'event_value': '10',
                                'event_params': {
                                    'project_id': projectId,
                                    'user_id': reduxUserId,
                                    'report_id': data.id
                                }
                            });
                        }
                    })
                }
            } else {
                if (isCreate || data.author_id === reduxUser.id) {
                    result.push({
                        text: trans('Create based on', language),
                        onClick: () => {
                            navigate(`/projects/${projectId}/custom-reports/${data.id}/create/`);
                        }
                    })
                }
                if (isEdit || data.author_id === reduxUser.id) {
                    result.push({
                        text: trans('Edit', language),
                        onClick: () => {
                            navigate(`/projects/${projectId}/custom-reports/${data.id}/edit/`);
                        }
                    })
                }
                if (isDelete || data.author_id === reduxUser.id) {
                    result.push({
                        text: trans('Delete', language),
                        onClick: () => {
                            dispatch(deleteReport({projectId, reportId: data.id}));
                            sendSdk('event', {
                                'event_type': 'delete',
                                'event_category': 'custom_reports',
                                'event_name': 'action',
                                'event_value': '10',
                                'event_params': {
                                    'project_id': projectId,
                                    'user_id': reduxUserId,
                                    'report_id': data.id
                                }
                            });
                        }
                    })
                }
            }
            return {items: result};
        }
        return (
            <div className={`reports-table-body__item ${addFlag ? 'active' : ''}`}>
                <button className="reports-table-body__head" onClick={(e: any) => {
                    if (!e.target.closest('.reports-table-body__action'))
                        navigate(`/projects/${projectId}/custom-reports/${data.id}/view/`);
                }}>
                    <div>{data.name ? data.name : '-'}</div>
                    <div>{data.period && (Periods(language) as any)[data.period] ? (Periods(language) as any)[data.period] : data.period === 'choice' && data.interval_from && data.interval_to ? `${data.interval_from} - ${data.interval_to}` : '-'}</div>
                    <div>{data.status ? trans(data.status, language) : '-'}</div>
                    <div>{(isEdit || isCreate || isDelete || reduxUser.id == data.author_id) ? data.author_name: trans('Name hidden', language)}</div>
                    <div>{data.created_at ? data.created_at : "-"}</div>
                    <div className="reports-table-body__action">
                        {(isCreate || isEdit || isDelete || data.author_id == reduxUser.id) && <Popup
                            btn={{
                                icon: {
                                    Component: DotsIcon,
                                    width: 6,
                                    height: 22
                                },
                                isIconBtn: true,
                                size: 'lg'
                            }}
                            dropdownList={getOptionsByPermissions(data.status, data.frequency_type)}
                        />}
                    </div>
                </button>
            </div>
        )
    }

    return (
        <>
          {(isLoading) &&
            <Loader inBlock={true}/>}
          { reduxReports.length > 0 ?
            <div className="reports page-content__content">
              <div className="reports__header">
                <SearchForm
                  value={searchValue}
                  onChangeCb={(value) => {
                    onChangeValueSearch(value);
                  }}
                />
                {isCreate && <button className={'reports__add'} onClick={()=>navigate(`/projects/${projectId}/custom-reports/create/`)}><span></span></button>}
              </div>
              <div className='reports__wrapper'>
                {dataWrapper && dataWrapper.length > 0 &&
                  <div className="reports-table">
                    <Thead title={title} onChangeSort={onChangeSortTableCustom}/>
                    <div className='reports-table-body'>
                        {dataWrapper.slice((currentPage-1)*limitChannelCustom, currentPage*limitChannelCustom).map((item:any, index:number)=> (
                            <Tbody key={index} projectId={projectId} data={item} />
                        ))}
                    </div>
                  </div>
                }
                { dataWrapper.length === 0 &&
                    <Empty title={trans('Nothing found', language)} />
                }
              </div>

            {
              dataWrapper.length > 0 && per_page_max > 1 &&
              <Pagination
                    sortFront={true}
                    onChangeLimitChannel={null}
                    limitChannelCustom={limitChannelCustom}
                    setLimitChannelCustom={setLimitChannelCustom}
                    per_page_max={per_page_max}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    bigData={true}
              />
            }
            </div>
          : !(isLoading) &&
            <div className="reports__empty">
                <h2 className="title title--black">
                    {trans('Custom reports', language)}<br/> {trans('not founds', language)}
                </h2>
                <Btn
                  text={trans('Create', language)}
                  icon={{
                    Component: PlusIcon,
                    width: 12,
                    height: 12,
                  }}
                  color='light-blue'
                  size='md'
                  onClick={()=>{
                      sendSdk('event', {
                          'event_type': 'link',
                          'event_category': 'custom_reports',
                          'event_name': 'create',
                          'event_value': '10',
                          'event_params': {
                              'project_id': projectId,
                              'user_id': reduxUserId
                          }
                      });
                      navigate(`/projects/${projectId}/custom-reports/create/`);
                  }}
                  disabled={!isCreate}
                />
            </div>
          }
        </>
    );
};


export default Reports;